<div class="dialog-wrapper">
  <app-dialog-header
    title="Change Password"
    [dialogRef]="dialogRef"
  ></app-dialog-header>
  <div mat-dialog-content>
    <form
      [formGroup]="changePasswordForm"
      (ngSubmit)="onSubmit()"
      class="w-full change-pass-form"
    >
      <mat-dialog-content>
        <div class="change-password-form">
          <div
            class="form-control-wrapper"
            [ngClass]="{
              'invalid-field': currentPassword?.errors && currentPassword?.dirty
            }"
          >
            <label for="password">Current Password</label>
            <input
              type="password"
              placeholder="Type your current password"
              id="currentPassword"
              class="form-control"
              formControlName="currentPassword"
              [ngClass]="{
                'border-status-danger':
                  currentPassword?.errors && currentPassword?.dirty
              }"
            />
            <app-input-error
              class="current-password-required"
              [condition]="
                !!currentPassword?.dirty &&
                !!currentPassword?.hasError('required')
              "
              errorMessage="This field is required"
            >
            </app-input-error>
          </div>

          <div
            class="form-control-wrapper"
            [ngClass]="{
              'invalid-field':
                changePasswordForm.errors?.['passwordRequirements'] &&
                password?.dirty
            }"
          >
            <label for="password">New Password</label>
            <input
              type="password"
              placeholder="Type your new password"
              id="password"
              class="form-control"
              formControlName="password"
              [ngClass]="{
                'border-status-danger':
                  changePasswordForm.errors?.['passwordRequirements'] &&
                  password?.dirty
              }"
            />

            @if ( password?.dirty &&
            changePasswordForm.errors?.['passwordRequirements'] ) {
            <app-input-error
              class="password-required"
              [type]="getPassReq('required') ? 'error' : 'success'"
              errorMessage="This field is required"
            >
            </app-input-error>

            <app-input-error
              class="password-length"
              [type]="getPassReq('isMinLength') ? 'error' : 'success'"
              errorMessage="Your password must be at least 8 characters long"
            >
            </app-input-error>

            <app-input-error
              class="password-numeric"
              [type]="getPassReq('hasNumber') ? 'error' : 'success'"
              errorMessage="Your password must contain a number"
            >
            </app-input-error>

            <app-input-error
              class="password-special-character"
              [type]="getPassReq('hasSpecial') ? 'error' : 'success'"
              errorMessage="Your password must contain a special character"
            >
            </app-input-error>

            <app-input-error
              class="password-same-current-password"
              [type]="getPassReq('notSameAsCurrent') ? 'error' : 'success'"
              errorMessage="New password must not match the current password"
            >
            </app-input-error>
            }
          </div>

          <div
            class="form-control-wrapper"
            [ngClass]="{
              'invalid-field':
                (!!changePasswordForm.hasError('passwordMismatch') ||
                  repeatPassword?.errors) &&
                repeatPassword?.dirty
            }"
          >
            <label for="password">Re-type New Password</label>
            <input
              type="password"
              placeholder="Re-type your new password"
              id="repeatPassword"
              class="form-control"
              formControlName="repeatPassword"
              [ngClass]="{
                'border-status-danger':
                  (repeatPassword?.errors ||
                    changePasswordForm.errors?.['passwordMismatch']) &&
                  repeatPassword?.dirty
              }"
            />
            <app-input-error
              class="repeat-password-required"
              [condition]="
                !!repeatPassword?.dirty &&
                !!repeatPassword?.hasError('required')
              "
              errorMessage="This field is required"
            >
            </app-input-error>
            <app-input-error
              class="repeat-password-not-matched"
              [condition]="
                !!repeatPassword?.dirty &&
                !!changePasswordForm.hasError('passwordMismatch')
              "
              errorMessage="Your passwords do not match"
            >
            </app-input-error>
          </div>

          @if (isError) {
          <div
            class="relative box-border pt-3 pb-1 text-status-danger text-center"
          >
            {{ isError }}
          </div>
          }
        </div>
      </mat-dialog-content>
      <div class="dialog-actions full-size">
        <app-new-action-button
          class="w-full h-[44px] mt-5"
          [fullWidth]="true"
          (actionCompleted)="onSubmit()"
          text="Save"
          [isDisabled]="!changePasswordForm.valid"
          [themedButton]="'primary'"
        ></app-new-action-button>
      </div>
    </form>
  </div>
</div>
